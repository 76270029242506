import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/crm/partner',
        component: () => import('@/layout/PartnerLayout.vue'),
        redirect: { name: 'Partner' },
        children: [
            {
                path: '',
                name: 'Partner',
                component: () => import('@/views/PartnerView.vue'),
            },
            {
                path: 'buat',
                name: 'CreatePartner',
                component: () => import('@/views/CreatePartnerView.vue'),
            },
            {
                path: 'edit',
                name: 'EditPartner',
                component: () => import('@/views/EditPartnerView.vue'),
            },
            {
                path: 'detail',
                name: 'DetailPartner',
                component: () => import('@/views/DetailPartnerView.vue'),
            },
            {
                path: 'kontak-partner',
                name: 'ContactPartner',
                component: () => import('@/views/ContactView.vue'),
            },
            {
                path: 'kontak-partner/edit',
                name: 'EditContactPartner',
                component: () => import('@/views/EditContactView.vue'),
            },
            {
                path: 'kontak-partner/buat',
                name: 'CreateContactPartner',
                component: () => import('@/views/CreateContactView.vue'),
            },
            {
                path: 'kontak-partner/detail',
                name: 'DetailContactPartner',
                component: () => import('@/views/DetailContactView.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
